"use client";

import State from "@/components/state";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { useTranslations } from "next-intl";

export default function NotFoundPage() {
  const t = useTranslations("NotFoundPage");
  return (
    <State
      icon={ExclamationTriangleIcon}
      title={t("title")}
      description={t("description")}
      action={{
        text: t("action"),
        onClick: () => window.history.back(),
      }}
    />
  );
}
